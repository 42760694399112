<template>
  <div class="autoupload">
    <div class="flex xs12">
     
        <va-card title="一键上传图像">
            <va-toggle
            outline
            small
            v-model="uploadStatus"
            :label="uploadDesc"
            @click.native="changeStatus()"
            />
            <div class="flex md12 sm12 xs12">
            <va-input v-model="qrcode" 
                placeholder="扫码输入对应照片编号" 
                @keyup.enter.native="submit()"/>
            </div>

        </va-card>

        <img
            :src="targetUrl"
            style="width: 50%; height: 50%;"
        ></img>


      
    </div>
  </div>
</template>

<script>
// import { compress, compressAccurately } from "image-conversion";
import * as imageConversion from "image-conversion";
import axios from "axios";
export default {
  name: "file-upload",
  data() {
    return {
      uploadStatus: false,
      uploadDesc: "自动上传已关闭",
      curFilename: "",
      oldFilename: "",
      qrcode: "",
      qrcodeErrors: [],
      fileErrors: [],
      toastText: "",
      toastDuration: 2500,
      toastPosition: "top-center",
      isToastFullWidth: false,
      targetUrl: require("../../../../../background/00.jpg"),
      timerId:null,
      file:null,
    };
  },
  computed: {
    
  },
  methods: {
        changeStatus() {
            if(this.uploadStatus===true)
            {
                this.uploadDesc="自动上传已开启";
                this.timerId = setInterval(this.requestPicture, 300);
            }
            else{
                this.uploadDesc="自动上传已关闭";
                clearInterval(this.timerId);
            }
        },
        requestPicture() {
            // console.log("3s");
            const instance1 = axios.create({
                baseURL: "http://localhost:5055",
                timeout: 5000,
            });
            instance1({
                url: "/filelist",
                method: "get",
            })
            .then((res) => {
                // console.log(res);
                if(res.data.count>0)
                {
                    this.curFilename = res.data.file_list[0].path;
                    // console.log(this.curFilename);
                }
            })
            .catch((error) => {
                console.log(error);
            });

            if(this.curFilename!==this.oldFilename)
            {
                this.oldFilename=this.curFilename;
                const instance2 = axios.create({
                baseURL: "http://localhost:5055",
                timeout: 5000,
                });
                instance2({
                    url: "/file/"+this.oldFilename,
                    method: "get",
                    responseType: "blob",
                })
                .then((res) => {
                    // console.log(res);
                    this.file=res.data;
                    // console.log(typeof(this.file));
                })
                .catch((error) => {
                    console.log(error);
                });
                this.targetUrl = "http://localhost:5055/file/"+this.oldFilename;
            }
        },
        submit(){
            this.qrcodeErrors = this.qrcode ? [] : ["qrcode is required"];
            // console.log("");
            console.log(this.file)
            imageConversion.compressAccurately(this.file, 1024).then((res) => {
            var pic = imageConversion.filetoDataURL(res).then((res) => {
                this.axios
                .post(
                    "/v1/picture/upload",
                    {
                    qrcode: this.qrcode,
                    picture: res,
                    access_token: sessionStorage["access_token"],
                    },
                    {
                    headers: {
                        Authorization: "Bearer " + sessionStorage["access_token"],
                    },
                    }
                )
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                    this.toastText = "上传成功";
                    this.qrcode = "";
                    this.showToast(this.toastText, {
                        position: this.toastPosition,
                        duration: this.toastDuration,
                        fullWidth: this.isToastFullWidth,
                    });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 409) {
                    this.toastText = "该编号已绑定过其他照片";
                    this.showToast(this.toastText, {
                        position: this.toastPosition,
                        duration: this.toastDuration,
                        fullWidth: this.isToastFullWidth,
                    });
                    }
                });//catch end
             });//file to dataurl end
          });//compress end
        },//submit() end

  },
};
</script>